import logo from './logo.svg';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

import Lottie from 'react-lottie';
import { useEffect } from 'react';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: require("./anim.json"),
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const router = createBrowserRouter([
  {
    path: "*",
    element: <div style={{ height: "100vh", width: "100vw", flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
      {/* <Lottie options={defaultOptions} style={{ maxHeight: "800px", maxWidth: "800px" }} /> */}
      <div className="main">
        <div id='content'>
          <div className='title'>
            <span>TITAN'S FALLEN</span>
          </div>
          <p>COMING SOON!</p>
          <section>
            <ul id="countdown">
              <li><span className="days timenumbers">01</span>
                <p className="timeRefDays timedescription">days</p>
              </li>
              <li><span className="hours timenumbers">00</span>
                <p className="timeRefHours timedescription">hours</p>
              </li>
              <li><span className="minutes timenumbers">00</span>
                <p className="timeRefMinutes timedescription">minutes</p>
              </li>
              <li><span className="seconds timenumbers yellow-text">00</span>
                <p className="timeRefSeconds timedescription">seconds</p>
              </li>
            </ul>
          </section>
          <p>OUR SITE IS CURRENTLY ON MAINTANENCE.<br /><br />WE'LL BE BACK WITH OUR NEW DESIGNED WEBSITE SOON!
            <br />
            <br />
            <p>Copyright ©️ 2024 Titan Has Fallen LLC</p>
          </p>

        </div >
      </div >
    </div>,
  }
]);

// array of custom settings
var settings = {
  date: "10 May 2024 20:00:00",
  format: null
};

function App() {
  useEffect(() => {
    countdown(
      document.getElementById("countdown"),
      {
        //The countdown end date
        date: "10 January 2022 20:00:00",

        // on (03:07:52) | off (3:7:52) - two_digits set to ON maintains layout consistency
        format: "on"
      }
    );

    function setHeights() {
      var windowHeight = window.innerHeight;
      Array.from(document.getElementsByClassName("slide")).forEach(el => {
      });
    }

    setHeights();

    document.addEventListener("resize", () => {
      setHeights();
    })

    function addSticky() {
      Array.from(document.getElementsByClassName("slide")).forEach((el) => {
        var scrollerAnchor = el.offset().top;
        if (window.scrollY >= scrollerAnchor) {
          el.classList.add("fix-it");
        } else {
          el.classList.remove("fix-it");
        }
      });
    }

    document.addEventListener("scroll", () => {
      addSticky();
    });
  }, [])

  var countdown = function (thisEl, options, callback) {
    // append the settings array to options
    if (options) {
      settings = { ...settings, options };
    }

    //create the countdown processing function
    function countdown_proc() {
      var eventDate = Date.parse(settings.date) / 1000;
      var currentDate = Math.floor(new Date() / 1000);

      if (eventDate <= currentDate) {
        callback?.call(this);
        clearInterval(interval);
      }

      var seconds = eventDate - currentDate;

      var days = Math.floor(seconds / (60 * 60 * 24));
      //calculate the number of days

      seconds -= days * 60 * 60 * 24;
      //update the seconds variable with number of days removed

      var hours = Math.floor(seconds / (60 * 60));
      seconds -= hours * 60 * 60;
      //update the seconds variable with number of hours removed

      var minutes = Math.floor(seconds / 60);
      seconds -= minutes * 60;
      //update the seconds variable with number of minutes removed

      //conditional statements
      if (days == 1) {
        thisEl.getElementsByClassName("timeRefDays")[0].textContent = "day";
      } else {
        thisEl.getElementsByClassName("timeRefDays")[0].textContent = "days";
      }
      if (hours == 1) {
        thisEl.getElementsByClassName("timeRefHours")[0].textContent = "hour";
      } else {
        thisEl.getElementsByClassName("timeRefHours")[0].textContent = "hours";
      }
      if (minutes == 1) {
        thisEl.getElementsByClassName("timeRefMinutes")[0].textContent = "minute";
      } else {
        thisEl.getElementsByClassName("timeRefMinutes")[0].textContent = "minutes";
      }
      if (seconds == 1) {
        thisEl.getElementsByClassName("timeRefSeconds")[0].textContent = "second";
      } else {
        thisEl.getElementsByClassName("timeRefSeconds")[0].textContent = "seconds";
      }

      //logic for the two_digits ON setting
      if (settings.format == "on") {
        days = String(days).length >= 2 ? days : "0" + days;
        hours = String(hours).length >= 2 ? hours : "0" + hours;
        minutes = String(minutes).length >= 2 ? minutes : "0" + minutes;
        seconds = String(seconds).length >= 2 ? seconds : "0" + seconds;
      }

      //update the countdown's html values.
      thisEl.getElementsByClassName("days")[0].textContent = days;
      thisEl.getElementsByClassName("hours")[0].textContent = hours;
      thisEl.getElementsByClassName("minutes")[0].textContent = minutes;
      thisEl.getElementsByClassName("seconds")[0].textContent = seconds;
    }

    //run the function
    countdown_proc();

    //loop the function
    var interval = setInterval(countdown_proc, 1000);
  };


  return <RouterProvider router={router} />;
}

export default App;
